import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { FLEET_PREFIX, TEAM_PREFIX, TOAST_MSG_TYPE, getConstantData,Months } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useFleetList } from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';
import { useTeamAttendanceList } from '../hooks/MemberHooks';

const TeamMember = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 10, offset: 0, searchTerm: "", paginated: true})
    const {memberData, loading, error, total} = useTeamAttendanceList(axiosPrivate, params)

    const render = useMemo(() => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name'
            },
            {
                title: 'Month',
                dataIndex: 'department_id',
                render: (text, record) => {
                    return Months[record.month-1]
                }
            },
            {
                title: 'Year',
                dataIndex: 'year'
            },
            {
                title: 'Total Hours',
				render: (text, record) => {
                    return Math.floor(record.total_month_minutes / 60)+" H "+(record.total_month_minutes % 60)+" M" ;
                }
            },
			{
                title: 'Total Exp',
                dataIndex: 'total_amount_exp'
            },
        ]
        if(auth.permissions.includes("EditTeam")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action">
                           <Link className="dropdown-item" to={`/attendance/${record.att_user_id}/${record.year}/${record.month}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-info" />Details</Link>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const getMemberDepartment = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("TEAM_DEPARTS")) {
            statusObj = auth.constants["TEAM_DEPARTS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Fleets</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Attendance List</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Attendance List</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={memberData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default TeamMember;