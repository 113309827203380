import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE, STOCK_STATUS_LIST,PRODUCT_PREFIX } from '../../utils/Constants';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { fleetService } from '../../Services/FleetService';
import { CKEditor } from 'ckeditor4-react';

const initialState = {id: 0, supplier_name: "", supplier_product_price: 0, supplier_product_barcode: "", sale_price: 0, discounted_price: 0, stock_batch_number: "", stock_qty: 0,stock_invoice: "", supplier_product_expiry_date: "", stock_sale_order: 0,stock_status:0}
const FleetForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    console.log(props)
    useEffect(() => {
        if(props.stock_id) {
            fleetService.getProductStocksByID(axiosPrivate, props.stock_id).then(res => {
				console.log("res",res)
                if(res.data.ProductSupplierStockById) {
                    const data = res.data.ProductSupplierStockById.data
                    setFormState({
                        ...data,
						supplier_product_expiry_date: data.supplier_product_expiry_date ? moment(data.supplier_product_expiry_date) : null
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${FLEET_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.stock_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    
    const onChangeModalYear = (value, dateString) => {
        setFormState({...formState, supplier_product_expiry_date: moment(dateString), fleetYear: dateString})
    }
    const onChangeInsuranceExpiry = (value, dateString) => {
        setFormState({...formState, insurance_expiry: moment(dateString), insuranceExpiry: dateString})
    }
	const onChangeStatus = (e) => {		
		setFormState({...formState, stock_status: e})
	}
	
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.supplier_name) {
            showToast("Please Fill supplier name", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.supplier_product_price) {
            showToast("Please Fill supplier product price", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.stock_batch_number) {
            showToast("Please Fill stock batch number", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.stock_qty) {
            showToast("Please Fill stock qty", TOAST_MSG_TYPE.ERROR)
            return
        }
        let param = {
            id: formState.id,
            product_id: Number(props.product_id),
            supplier_name: formState.supplier_name,
            supplier_product_price: parseFloat(formState.supplier_product_price),
            supplier_product_barcode: formState.supplier_product_barcode,
            sale_price: parseFloat(formState.sale_price),
            discounted_price: parseFloat(formState.discounted_price),
            stock_batch_number: formState.stock_batch_number,
            stock_qty: Number(formState.stock_qty),
			stock_invoice: formState.stock_invoice,
			supplier_product_expiry_date: moment(formState.supplier_product_expiry_date).format("YYYY-MM-DD"),
			stock_sale_order: Number(formState.stock_sale_order),
			stock_status: Number(formState.stock_status),
            other_details: {}
        }
        const apiPromise = fleetService.addUpdateProductSupplierStocks(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addUpdateProductSupplierStocks) {
                showToast(props.crud === "create" ? "Stock Added Successfully!" : "Stock Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${PRODUCT_PREFIX}/stocks/${props.product_id}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>supplier name*</label>
                            <input type="text" className='form-control' name='supplier_name' value={formState.supplier_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>supplier product price*</label>
                            <input type="number" className='form-control' name='supplier_product_price' value={formState.supplier_product_price} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>supplier product barcode*</label>
                            <input type="text" className='form-control' name='supplier_product_barcode' value={formState.supplier_product_barcode} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>sale price*</label>
                            <input type="number" className='form-control' name='sale_price' value={formState.sale_price} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>discounted price</label>
                            <input type="number" className='form-control' name='discounted_price' value={formState.discounted_price} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>stock batch number</label>
                            <input type="text" className='form-control' name='stock_batch_number' value={formState.stock_batch_number} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>stock qty*</label>
                            <input type="text" className='form-control' name='stock_qty' value={formState.stock_qty} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>stock invoice</label>
                            <input type="text" className='form-control' name='stock_invoice' value={formState.stock_invoice} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>stock sale order*</label>
                            <input type="number" className='form-control' name='stock_sale_order' value={formState.stock_sale_order} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>stock status*</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								value={formState.stock_status}
								options={STOCK_STATUS_LIST}
								onChange={onChangeStatus}
							/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>product expiry date*</label>
                            <DatePicker
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeModalYear} 
                                value={formState.supplier_product_expiry_date || ""}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default FleetForm