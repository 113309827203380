import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useNavigate,useParams } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE, getConstantData, API_BASE_URL,PRODUCT_PREFIX,STOCK_STATUS_LIST } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useProductSupplierStocks } from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';

const FleetList = () => {	
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
	let { product_id } = useParams()
    const [params, setParams] = useState({paginated: true, limit: 20, offset: 0, search: "", product_id: product_id})
    const {fleetData, loading, error, total} = useProductSupplierStocks(axiosPrivate, params)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Supplier',
                dataIndex: 'supplier_name'
            },
			{
                title: 'Price',
                dataIndex: 'supplier_product_price'
            },
			{
                title: 'Sale Price',
                dataIndex: 'total',
				render: (text,row) => {
					return row.discounted_price && row.discounted_price>0 ? row.discounted_price : row.sale_price
				}
            },
			{
                title: 'Batch No',
                dataIndex: 'stock_batch_number'
            },
			{
                title: 'Qty',
                dataIndex: 'stock_qty'
            },
			{
                title: 'Invoice',
                dataIndex: 'stock_invoice'
            },
			{
                title: 'Product Expiry Date',
                dataIndex: 'supplier_product_expiry_date'
            },
			{
                title: 'Sale order',
                dataIndex: 'stock_sale_order'
            },
			{
                title: 'Stock Status',
                dataIndex: 'stock_status',
				render : (text,row) => {
					var typeObj=STOCK_STATUS_LIST.find((s) => s.value == row.stock_status);
					console.log(typeObj)
					return typeObj && typeObj.hasOwnProperty("label") ? typeObj.label : ""
				}
            },{
                title: 'Action',
                dataIndex: 'id',
				render: (text,row) => {
                   return <a href={`${PRODUCT_PREFIX}/stocks/edit/${product_id}/${row.id}`}><i class="btn btn-primary">Edit</i></a>
                } 
            }
        ]
        return columns
    }, [auth.permissions])

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
	const createLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
	const downloadExcel = (e) => {
        let params={
			is_excel: true,
			report_name: "PRODUCT_SALES_REPORT",
			paginated: false, 
			limit: 20, 
			offset: 0, 
			search: ""
		}
        const apiPromise = fleetService.orderReportsData(axiosPrivate, params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.orderReportsData.path) {
                const url = `${API_BASE_URL}${res.data.orderReportsData.path.replace("./", "")}`
                createLink(url)
            }
        })
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products In Cart</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-9">
                            <h3 className="page-title">Manage Products Supplier Stocks</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Manage Products Supplier Stocks</li>
                            </ul>
                        </div>
						<div className="col-md-3">
                                <Link to={`${PRODUCT_PREFIX}/stocks/create/${product_id}`} className="btn btn-primary btn-width float-right d-block">Add New Supplier Stock</Link>
                            </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
							
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 20, 
                                    itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={fleetData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default FleetList;