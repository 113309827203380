import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { FLEET_PREFIX, TEAM_PREFIX, TOAST_MSG_TYPE, getConstantData,Months,BANNER_IMG,ORDER_STATUS_IMG } from '../utils/Constants';
import { Table,Image } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useFleetList } from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';
import { useSingleTeamAttendanceList } from '../hooks/MemberHooks';

const TeamMember = () => {	
    let { user_id,year,month } = useParams()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 10, offset: 0, month:month,year:year,userId:user_id, paginated: true})
    const {data, loading, error, total,month_data } = useSingleTeamAttendanceList(axiosPrivate, params)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Date',
                dataIndex: 'att_date'
            },
            {
                title: 'In Time',
                dataIndex: 'att_in_time',
                render: (text, record) => {
                    return text
                }
            },
			{
                title: 'Img',
				render: (text, item) => {
					return <Image height={30} src={`${BANNER_IMG}/${item.att_image_in}`}/>
				}
            },
            {
                title: 'Out Time',
                dataIndex: 'att_out_time'
            },
			{
                title: 'Img',
				render: (text, item) => {
					return <Image height={30} src={`${BANNER_IMG}/${item.att_image_out}`}/>
				}
            },
            {
                title: 'Total Hours',
				render: (text, record) => {
					return Math.floor(record.att_total_time_minutes / 60)+" H "+(record.att_total_time_minutes % 60)+" M" ;
                }
            },
			{
                title: 'Lat Lng(IN - OUT)',
                dataIndex: 'att_user_in_latlng',
				render: (text, record) => {
                    return `(${record.att_user_in_latlng})-(${record.att_user_out_latlng})`
                }
            },
			{
                title: 'Day Exp',
                dataIndex: 'att_exp_amount'
            },
			{
                title: 'Note',
                dataIndex: 'att_note'
            },
            {
                title: 'Exp Images',
                dataIndex: 'att_exp_images',
				render: (t, r) => {
					if(t && (t[0] || t[1] || t[2])) {
                        return (<>
                            {
                                t[0] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[0]}`}/> : null
                            }
                            {
                                t[1] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[1]}`}/> : null
                            }
                            {
                                t[2] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[2]}`}/> : null
                            }
							{
                                t[3] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[3]}`}/> : null
                            }
							{
                                t[4] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[4]}`}/> : null
                            }
							{
                                t[5] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[5]}`}/> : null
                            }
							{
                                t[6] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[6]}`}/> : null
                            }
							{
                                t[7] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[7]}`}/> : null
                            }
							{
                                t[8] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[8]}`}/> : null
                            }
							{
                                t[9] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[9]}`}/> : null
                            }
							{
                                t[10] ? 
                                <Image width={20} src={`${ORDER_STATUS_IMG}/${t[10]}`}/> : null
                            }
                        </>)
                    }
                }
            }			
            			
        ]
		if(auth.permissions.includes("EditTeam")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action">
                           <Link className="dropdown-item btn btn-primary" to={`/updateAttendance/edit/${record.id}`} data-toggle="modal" data-target="#edit_leave">EDIT</Link>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const getMemberDepartment = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("TEAM_DEPARTS")) {
            statusObj = auth.constants["TEAM_DEPARTS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Fleets</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Monthly Attendance List</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active"><Link to={`/team/edit/${user_id}`}>Attendance : {month}/{year}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-4'>
                               <b>{Months[month-1]}/{year} -</b> Total Hrs  : {month_data && month_data[0] ? Math.floor(month_data[0].total_month_minutes / 60)+" Hours "+(month_data[0].total_month_minutes % 60)+" Minutes" : 0}
                            </div>
							<div className='col-md-4'>
                                Total Amount({month_data && month_data[0] ? month_data[0].hourly_rate : 0}$/hr) : {month_data && month_data[0] ? parseFloat(month_data[0].total_month_minutes * (month_data[0].hourly_rate/60)).toFixed(2) : 0}$
                            </div>
							<div className='col-md-4'>
                                Total Expense: {month_data && month_data[0] ? month_data[0].total_exp_amount : 0}$
                            </div>
							
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={data}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default TeamMember;