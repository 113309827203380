import { Table,Image } from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useParams } from 'react-router-dom';
import ImageUpload from "../Components/ImageUpload"
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useSupportListDetailsById,useCustomerDetailsById} from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData,CUSTOMER_PREFIX,ORDER_PREFIX, ORDER_STATUS_IMG,CATEGORY_IMG} from '../utils/Constants';
import { productService } from '../Services/ProductService';
const initialState = { ticket_message: "", comment_image1: {server: ""},comment_image2: {server: ""},comment_image3: {server: ""},comment_image4: {server: ""},}
const CardDetails = (props) => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    let { ticketId } = useParams()
	const [formState, setFormState] = useState({...initialState})
	const [sendEmail,setSendEmail]= useState(false)
	const [sendNotification,setSendNotification]= useState(false)
	const [params, setParams] = useState({ticket_id: ticketId})
	const [customerData,setCustomerData]= useState([])
    const {fleetData, loading, error, total} = useSupportListDetailsById(axiosPrivate, params)
	useEffect(() => {
		if(fleetData && fleetData[0] && fleetData[0].ticket_customer_id){
			const apiPromise = fleetService.getCustomerByID(axiosPrivate, fleetData[0].ticket_customer_id)
			showPromiseToast(apiPromise, "Please Wait...")
			apiPromise.then(res => {
				if(res.data.customerDetailsByid) {
					setCustomerData(res.data.customerDetailsByid.customer_details)
				}
			})		
		}
	  }, [fleetData]); // <- add the count variable here
	  console.log(customerData,"customerData")
    const render = useMemo(() => {
        const columns = [	
			{
                title: 'Created At',
                dataIndex: 'createdAt',
				render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            },	    
            {
                title: 'Msg#',
                dataIndex: 'ticket_message'
            },
			{
               title: 'TriggerBy',
               render: (text,row) => {
                    return row.ticket_triggered_by && row.ticket_triggered_by>0 ? row.ticket_action : row.ticket_status;
                }
            },
			{
                title: 'Images',
                dataIndex: 'ticket_images',
				render: (t, r) => {
					if(t[0] || t[1] || t[2]) {
                        return (<>
                            {
                                t[0] ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t[0]}`}/> : null
                            }
                            {
                                t[1] ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t[1]}`}/> : null
                            }
                            {
                                t[2] ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t[2]}`}/> : null
                            }
							{
                                t[3] ? 
                                <Image width={100} src={`${ORDER_STATUS_IMG}/${t[3]}`}/> : null
                            }
                        </>)
                    }
                }
            }
        ]
        return columns
    }, [auth.permissions])

    const onChangeField = (e) => {  
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.ticket_message) {
            showToast("Please enter comment", TOAST_MSG_TYPE.ERROR)
            return
        }
        let paramData = {
			send_notification: sendNotification,
			email_notification: sendEmail,
            ticket_message: formState.ticket_message,
			ticket_id: ticketId,
			customer_id: fleetData[0]['ticket_customer_id'],
			ticket_subject: fleetData[0]['ticket_subject'],
			ticket_type: fleetData[0]['ticket_type']
        }
		console.log(paramData)
	    if("local" in formState.comment_image1) {
            delete formState.comment_image1.local
        }
		if("local" in formState.comment_image2) {
            delete formState.comment_image2.local
        }
		if("local" in formState.comment_image3) {
            delete formState.comment_image3.local
        }
		if("local" in formState.comment_image4) {
            delete formState.comment_image4.local
        }
        const apiPromise = fleetService.addUpdateSupportComment(axiosPrivate, paramData,formState.comment_image1.server, formState.comment_image2.server,formState.comment_image3.server,formState.comment_image4.server )
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
                showToast(props.crud === "create" ? "Comment Submitted Sucessfully!" : "Comment Submitted Sucessfully", TOAST_MSG_TYPE.SUCCESS)
				window.location.reload();
        })
    }
	const checkboxChanged = (e) => {
		var newValue = !sendNotification
		setSendNotification(newValue)
    }
	const checkboxEmail = (e) => {
		var newValue = !sendEmail
		setSendEmail(newValue)
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Order Admin Comments</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Comments</li>
								<li className="breadcrumb-item active">#{ticketId}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-12'>
							 <ul className="breadcrumb">
                               <li className="breadcrumb-item active"><Link to={`/customers/edit/${customerData.id}`}>{customerData ? customerData.first_name+" "+customerData.last_name : null}</Link></li>
							   <li className="breadcrumb-item active">{customerData ? customerData.email : null}</li>
							   <li className="breadcrumb-item active">{customerData ? customerData.phone : null}</li>
							   <li className="breadcrumb-item active">{customerData ? customerData.customer_address_city : null}</li>
							 </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= {
									{
									total : total,
									pageSize: 5, 
									showSizeChanger : false,
									itemRender : itemRender 
									} 
								}
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={fleetData}
                            />
                        </div>
                    </div>
					<div className="card-body">
					<div className='col-md-12'>
						<div className='form-group'>
						<label>Comment*</label>
							<textarea 
							className='form-control'
							rows={6} 
							value={formState.ticket_message} 
							name='ticket_message' 
							onChange={onChangeField}>
							</textarea>
						</div>						
						<div className='row'>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image1.server}
							fileList={
							formState.comment_image1.server
								? [
									{
									uid: formState.comment_image1,
									url:"local" in formState.comment_image1 ? `${formState.comment_image1.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image1.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image1: file});
							}}
							onRemove={() => setFormState({...formState, comment_image1: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image2.server}
							fileList={
							formState.comment_image2.server
								? [
									{
									uid: formState.comment_image2,
									url:"local" in formState.comment_image2 ? `${formState.comment_image2.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image2.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image2: file});
							}}
							onRemove={() => setFormState({...formState, comment_image2: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image3.server}
							fileList={
							formState.comment_image3.server
								? [
									{
									uid: formState.comment_image3,
									url:"local" in formState.comment_image3 ? `${formState.comment_image3.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image3.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image3: file});
							}}
							onRemove={() => setFormState({...formState, comment_image3: {server: ""}})}
							crud="create"
						/>
						</div>
						<div className="col-sm-3 col-3">
							<label>Upload Image:</label>
							<ImageUpload
							fileName={formState.comment_image4.server}
							fileList={
							formState.comment_image4.server
								? [
									{
									uid: formState.comment_image4,
									url:"local" in formState.comment_image4 ? `${formState.comment_image4.local.url}` : `${ORDER_STATUS_IMG}/${formState.comment_image4.server}`
									}
								]
								: []
							}
							getFile={file => {
								setFormState({...formState, comment_image4: file});
							}}
							onRemove={() => setFormState({...formState, comment_image4: {server: ""}})}
							crud="create"
						/>
						</div>
						
						</div>
						<br/>
						<div className='row'>
						<div className="col-md-4">
						<input type="checkbox"  style={{marginRight: "10px"}} checked={sendNotification} onChange={checkboxChanged}/>
						<label><b>Send Notification To Customer ?</b></label>
						</div>
						<div className="col-md-4">
						<input type="checkbox"  style={{marginRight: "10px"}} checked={sendEmail} onChange={checkboxEmail}/>
						<label><b>Send Email To Customer ?</b></label>
						</div>
						</div>
						<button className='btn btn-primary mt-40 float-right text-white btn-width' onClick={submitHandler}>Submit</button>

					</div>
                         
					</div>
				 </div>
            </div>
        </div>
    
    );
}
export default CardDetails